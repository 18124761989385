.Calendar {
  font-family: 'Prompt Regular';
}

.responsive-calendar {
  font-size: 14px !important;
}

@media (max-width: 1500px) {
  .responsive-calendar {
    font-size: 14px !important;
  }
}

@media (max-width: 1200px) {
  .responsive-calendar {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .responsive-calendar {
    font-size: 10px !important;
  }
}

@media (max-width: 380px) {
  .responsive-calendar {
    font-size: 8px !important;
  }
}

/* Large screens */
@media (min-width: 2000px) {
  .responsive-calendar {
    font-size: 16px !important;
  }
}
